import {YellowPage,BlackPage,BluePage,GreenPage,RedPage,FirstPage, BlackPageEnd, GreenPageEnd, RedPageEnd, BluePageEnd, YellowPageEnd} from "../components";

export const ROUTES = [
  {
    path: "/",
    element: <FirstPage />,
  },
  {
    path: "/4",
    element: <YellowPage />,
  },
  {
    path: "/2",
    element: <GreenPage />,
  },
  {
    path: "/1",
    element: <RedPage />,
  },
  {
    path: "/5",
    element: <BluePage />,
  },
  {
    path: "/3",
    element: <BlackPage />,
  },
  {
    path: "/quizend3",
    element: <BlackPageEnd />,
  },
  {
    path: "/quizend5",
    element: <BluePageEnd />,
  },
  {
    path: "/quizend1",
    element: <RedPageEnd />,
  },
  {
    path: "/quizend2",
    element: <GreenPageEnd />,
  },
  {
    path: "/quizend4",
    element: <YellowPageEnd />,
  },
];
