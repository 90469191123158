import { useEffect, useState } from 'react';
import { ReactComponent as BlackPageSVG } from '../assets/SVG/BlackPage.svg';
import { useNavigate } from 'react-router-dom';
import data from '../assets/question-answer.json'; 
import BlackPageAfter from './AfterPages/BlackPageAfter';
import '../styles/Layout.css';
import { black1, black2, black3, black4, black5, black6, black7 } from '../assets';

const BlackPage = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const questions = data.black;
  const { questionText, options, information, picture } = questions[currentQuestionIndex];
  const [feedback, setFeedback] = useState('');

  const handleOptionClick = (isCorrect) => {
    if (isCorrect) {
      setFeedback(information);
      setAnswerCorrect(true);
      setShowFeedback(true);
    } else {
      setFeedback(information);
      setAnswerCorrect(false);
      setShowFeedback(true);
    }
  };

  const handleNext = () => {
    if (answerCorrect) {
      setShowFeedback(false);
      setAnswerCorrect(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (currentQuestionIndex === questions.length - 1) {
        navigate('/quizend3')
      }
    } else {
      setShowFeedback(false);
    }
  };

  useEffect(() => {
    const home_wiseathlete = document.getElementById('home_wiseathlete');
    const Option1black = document.getElementById('Option1-black');
    const Option2black = document.getElementById('Option2-black');
    const Option3black = document.getElementById('Option3-black');
    const answerOption1black = document.getElementById('answer-Option1-black');
    const answerOption2black = document.getElementById('answer-Option2-black');
    const answerOption3black = document.getElementById('answer-Option3-black');
    const picturePuzzle = document.getElementById('picture_puzzle-black');

    if (home_wiseathlete && Option1black && Option2black && Option3black && answerOption1black && answerOption2black && answerOption3black)
    {
      
      if (picturePuzzle) {

    // Switch case to select the correct image based on the picture value
        switch (picture) {
          case 1:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black1); // path to black1
            break;
          case 2:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black2); // path to black2
            break;
          case 3:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black3); // path to black3
            break;
          case 4:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black4); // path to black4
            break;
          case 5:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black5); // path to black5
            break;
          case 6:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black6); // path to black6
            break;
          case 7:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black7); // path to black7
            break;
          default:
            picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black1); // default image
            break;
        }
      }

      home_wiseathlete.addEventListener('mouseover', () => {
        home_wiseathlete.style.cursor = 'pointer';
      });

      home_wiseathlete.addEventListener('mouseout', () => {
        home_wiseathlete.style.cursor = 'default';
      });

      home_wiseathlete.addEventListener('click', () => {
        navigate('/');
      });

      Option1black.addEventListener('mouseover', () => {
        Option1black.style.cursor = 'pointer';
      });

      Option1black.addEventListener('mouseout', () => {
        Option1black.style.cursor = 'default';
      });
      answerOption1black.addEventListener('mouseover',()=>{
        answerOption1black.style.cursor = 'pointer';
      })

      Option2black.addEventListener('mouseover', () => {
        Option2black.style.cursor = 'pointer';
      });

      Option2black.addEventListener('mouseout', () => {
        Option2black.style.cursor = 'default';
      });
      answerOption2black.addEventListener('mouseover',()=>{
        answerOption2black.style.cursor = 'pointer';
      })
      Option3black.addEventListener('mouseover', () => {
        Option3black.style.cursor = 'pointer';
      });

      Option3black.addEventListener('mouseout', () => {
        Option3black.style.cursor = 'default';
      });
      answerOption3black.addEventListener('mouseover',()=>{
        answerOption3black.style.cursor = 'pointer';
      })

      answerOption1black.textContent = options[0].text;
      answerOption2black.textContent = options[1].text;
      answerOption3black.textContent = options[2].text;

      options.forEach((option, index) => {
        document.getElementById(`Option${index + 1}-black`).onclick = () => handleOptionClick(option.isCorrect);
        document.getElementById(`answer-Option${index + 1}-black`).onclick = () => handleOptionClick(option.isCorrect);
      });
    }
   
  }, [currentQuestionIndex, questionText, picture, options, navigate, showFeedback]);

  return (
    <div className="App-header-black">
      {showFeedback ? (
        <BlackPageAfter feedback={feedback} onNext={handleNext} isTrue={answerCorrect}/>
      ) : (
        <>
          <BlackPageSVG />
          <div className="question-text-div-black">
            <p className="question-text-black">{questionText}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default BlackPage;