import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BlackPageEndSVG } from '../../assets/SVG/BlackPageEnd.svg';
import { black7 } from '../../assets';
import '../../styles/Layout.css';

const BlackPageEnd = () => {
  const navigate = useNavigate();
  const feedback = "Congratulations, the quiz is complete. Happy learning!"; 
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxBlack = document.getElementById('correct-box-black');
      const picturePuzzle = document.getElementById('picture_puzzle-black');
      const correctBoxBlacktext = document.getElementById('correct-box-black-text');
      if (homeWiseathlete && correctBoxBlack  && picturePuzzle)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxBlack.addEventListener('mouseover', () => {
            correctBoxBlack.style.cursor = 'pointer';
        });
  
        correctBoxBlack.addEventListener('mouseout', () => {
            correctBoxBlack.style.cursor = 'default';
        });
        correctBoxBlack.addEventListener('click', () => {
            navigate('/');
        });

        picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', black7);


      }
     
    },[]);
  return (
    <div className="App-header-black">
      <BlackPageEndSVG/>
      <div className="question-text-div-black">
          <p className="question-text-black">{feedback}</p>
      </div>
    </div>
  );
}

export default BlackPageEnd;