import { useEffect, useState } from 'react';
import { ReactComponent as RedPageSVG } from '../assets/SVG/RedPage.svg';
import { useNavigate } from 'react-router-dom';
import data from '../assets/question-answer.json'; 
import RedPageAfter from './AfterPages/RedPageAfter';
import '../styles/Layout.css';
import { red1, red2, red3, red4 } from '../assets';

const RedPage = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const questions = data.red;
  const { questionText, options, information, picture } = questions[currentQuestionIndex];
  const [feedback, setFeedback] = useState('');

  const handleOptionClick = (isCorrect) => {
    if (isCorrect) {
      setFeedback(information);
      setAnswerCorrect(true);
      setShowFeedback(true);
    } else {
      setFeedback(information);
      setAnswerCorrect(false);
      setShowFeedback(true);
    }
  };

  const handleNext = () => {
    if (answerCorrect) {
      setShowFeedback(false);
      setAnswerCorrect(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (currentQuestionIndex === questions.length - 1) {
        navigate('/quizend1');
      }
    } else {
      setShowFeedback(false);
    }
  };

  useEffect(() => {
    const home_wiseathlete = document.getElementById('home_wiseathlete');
    const Option1red = document.getElementById('Option1-red');
    const Option2red = document.getElementById('Option2-red');
    const Option3red = document.getElementById('Option3-red');
    const answerOption1red = document.getElementById('answer-Option1-red');
    const answerOption2red = document.getElementById('answer-Option2-red');
    const answerOption3red = document.getElementById('answer-Option3-red');
    const picturePuzzle = document.getElementById('picture_puzzle-red');

    if (home_wiseathlete && Option1red && Option2red && Option3red && answerOption1red && answerOption2red && answerOption3red)
    {  


      if (picturePuzzle) {

        // Switch case to select the correct image based on the picture value
            switch (picture) {
              case 1:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', red1); // path to black1
                break;
              case 2:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', red2); // path to black2
                break;
              case 3:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', red3); // path to black3
                break;
              default:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', red1); // default image
                break;
            }
          }



      home_wiseathlete.addEventListener('mouseover', () => {
        home_wiseathlete.style.cursor = 'pointer';
      });

      home_wiseathlete.addEventListener('mouseout', () => {
        home_wiseathlete.style.cursor = 'default';
      });

      home_wiseathlete.addEventListener('click', () => {
        navigate('/');
      });

      Option1red.addEventListener('mouseover', () => {
        Option1red.style.cursor = 'pointer';
      });

      Option1red.addEventListener('mouseout', () => {
        Option1red.style.cursor = 'default';
      });
      answerOption1red.addEventListener('mouseover',()=>{
        answerOption1red.style.cursor = 'pointer';
      })

      Option2red.addEventListener('mouseover', () => {
        Option2red.style.cursor = 'pointer';
      });

      Option2red.addEventListener('mouseout', () => {
        Option2red.style.cursor = 'default';
      });
      answerOption2red.addEventListener('mouseover',()=>{
        answerOption2red.style.cursor = 'pointer';
      })
      Option3red.addEventListener('mouseover', () => {
        Option3red.style.cursor = 'pointer';
      });

      Option3red.addEventListener('mouseout', () => {
        Option3red.style.cursor = 'default';
      });
      answerOption3red.addEventListener('mouseover',()=>{
        answerOption3red.style.cursor = 'pointer';
      })

      answerOption1red.textContent = options[0].text;
      answerOption2red.textContent = options[1].text;
      answerOption3red.textContent = options[2].text;

      options.forEach((option, index) => {
        document.getElementById(`Option${index + 1}-red`).onclick = () => handleOptionClick(option.isCorrect);
        document.getElementById(`answer-Option${index + 1}-red`).onclick = () => handleOptionClick(option.isCorrect);
      });
    }
   
  }, [currentQuestionIndex, questionText, options,picture, navigate, showFeedback]);

  return (
    <div className="App-header-red">
      {showFeedback ? (
        <RedPageAfter feedback={feedback} onNext={handleNext} isTrue={answerCorrect}/>
      ) : (
        <>
          <RedPageSVG />
          <div className="question-text-div-red">
            <p className="question-text-red">{questionText}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default RedPage;