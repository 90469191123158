import { useEffect, useState } from 'react';
import { ReactComponent as BluePageSVG } from '../assets/SVG/BluePage.svg';
import { useNavigate } from 'react-router-dom';
import data from '../assets/question-answer.json'; 
import BluePageAfter from './AfterPages/BluePageAfter';
import '../styles/Layout.css';
import { blue1, blue2, blue3, blue4, blue5 } from '../assets';


const BluePage = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const questions = data.blue;
  const { questionText, options, information, picture } = questions[currentQuestionIndex];
  const [feedback, setFeedback] = useState('');

  const handleOptionClick = (isCorrect) => {
    if (isCorrect) {
      setFeedback(information);
      setAnswerCorrect(true);
      setShowFeedback(true);
    } else {
      setFeedback(information);
      setAnswerCorrect(false);
      setShowFeedback(true);
    }
  };

  const handleNext = () => {
    if (answerCorrect) {
      setShowFeedback(false);
      setAnswerCorrect(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (currentQuestionIndex === questions.length - 1) {
        navigate('/quizend5');
      }
    } else {
      setShowFeedback(false);
    }
  };

  useEffect(() => {
    const home_wiseathlete = document.getElementById('home_wiseathlete');
    const Option1blue = document.getElementById('Option1-blue');
    const Option2blue = document.getElementById('Option2-blue');
    const Option3blue = document.getElementById('Option3-blue');
    const answerOption1blue = document.getElementById('answer-Option1-blue');
    const answerOption2blue = document.getElementById('answer-Option2-blue');
    const answerOption3blue = document.getElementById('answer-Option3-blue');
    const picturePuzzle = document.getElementById('picture_puzzle-blue');

    if (home_wiseathlete && Option1blue && Option2blue && Option3blue && answerOption1blue && answerOption2blue && answerOption3blue)
    {
      
      if (picturePuzzle) {

        // Switch case to select the correct image based on the picture value
            switch (picture) {
              case 1:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', blue2); // path to black1
                break;
              case 2:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', blue3); // path to black2
                break;
              case 3:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', blue4); // path to black3
                break;
              case 4:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', blue5); // path to black4
                break;
              default:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', blue2); // default image
                break;
            }
          }
      
      home_wiseathlete.addEventListener('mouseover', () => {
        home_wiseathlete.style.cursor = 'pointer';
      });

      home_wiseathlete.addEventListener('mouseout', () => {
        home_wiseathlete.style.cursor = 'default';
      });

      home_wiseathlete.addEventListener('click', () => {
        navigate('/');
      });

      Option1blue.addEventListener('mouseover', () => {
        Option1blue.style.cursor = 'pointer';
      });

      Option1blue.addEventListener('mouseout', () => {
        Option1blue.style.cursor = 'default';
      });
      answerOption1blue.addEventListener('mouseover',()=>{
        answerOption1blue.style.cursor = 'pointer';
      })

      Option2blue.addEventListener('mouseover', () => {
        Option2blue.style.cursor = 'pointer';
      });

      Option2blue.addEventListener('mouseout', () => {
        Option2blue.style.cursor = 'default';
      });
      answerOption2blue.addEventListener('mouseover',()=>{
        answerOption2blue.style.cursor = 'pointer';
      })
      Option3blue.addEventListener('mouseover', () => {
        Option3blue.style.cursor = 'pointer';
      });

      Option3blue.addEventListener('mouseout', () => {
        Option3blue.style.cursor = 'default';
      });
      answerOption3blue.addEventListener('mouseover',()=>{
        answerOption3blue.style.cursor = 'pointer';
      })

      answerOption1blue.textContent = options[0].text;
      answerOption2blue.textContent = options[1].text;
      answerOption3blue.textContent = options[2].text;

      options.forEach((option, index) => {
        document.getElementById(`Option${index + 1}-blue`).onclick = () => handleOptionClick(option.isCorrect);
        document.getElementById(`answer-Option${index + 1}-blue`).onclick = () => handleOptionClick(option.isCorrect);
      });
    }
   
  }, [currentQuestionIndex, questionText, options, picture, navigate, showFeedback]);

  return (
    <div className="App-header-blue">
      {showFeedback ? (
        <BluePageAfter feedback={feedback} onNext={handleNext} isTrue={answerCorrect}/>
      ) : (
        <>
          <BluePageSVG />
          <div className="question-text-div-blue">
            <p className="question-text-blue">{questionText}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default BluePage;