import { useEffect, useState } from 'react';
import { ReactComponent as YellowPageSVG } from '../assets/SVG/YellowPage.svg';
import { useNavigate } from 'react-router-dom';
import data from '../assets/question-answer.json'; 
import YellowPageAfter from './AfterPages/YellowPageAfter';
import '../styles/Layout.css';
import { yellow1, yellow2, yellow3 } from '../assets';

const YellowPage = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const questions = data.yellow;
  const { questionText, options, information, picture } = questions[currentQuestionIndex];
  const [feedback, setFeedback] = useState('');

  const handleOptionClick = (isCorrect) => {
    if (isCorrect) {
      setFeedback(information);
      setAnswerCorrect(true);
      setShowFeedback(true);
    } else {
      setFeedback(information);
      setAnswerCorrect(false);
      setShowFeedback(true);
    }
  };

  const handleNext = () => {
    if (answerCorrect) {
      setShowFeedback(false);
      setAnswerCorrect(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (currentQuestionIndex === questions.length - 1) {
        navigate('/quizend4');
      }
    } else {
      setShowFeedback(false);
    }
  };

  useEffect(() => {
    const home_wiseathlete = document.getElementById('home_wiseathlete');
    const Option1yellow = document.getElementById('Option1-yellow');
    const Option2yellow = document.getElementById('Option2-yellow');
    const Option3yellow = document.getElementById('Option3-yellow');
    const answerOption1yellow = document.getElementById('answer-Option1-yellow');
    const answerOption2yellow = document.getElementById('answer-Option2-yellow');
    const answerOption3yellow = document.getElementById('answer-Option3-yellow');
    const picturePuzzle = document.getElementById('picture_puzzle-yellow');

    if (home_wiseathlete && Option1yellow && Option2yellow && Option3yellow && answerOption1yellow && answerOption2yellow && answerOption3yellow)
    {  

      if (picturePuzzle) {

        // Switch case to select the correct image based on the picture value
            switch (picture) {
              case 1:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', yellow1); // path to black1
                break;
              case 2:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', yellow2); // path to black2
                break;
              case 3:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', yellow3); // path to black3
                break;
              default:
                picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', yellow1); // default image
                break;
            
              }
        }
          
      home_wiseathlete.addEventListener('mouseover', () => {
        home_wiseathlete.style.cursor = 'pointer';
      });

      home_wiseathlete.addEventListener('mouseout', () => {
        home_wiseathlete.style.cursor = 'default';
      });

      home_wiseathlete.addEventListener('click', () => {
        navigate('/');
      });

      Option1yellow.addEventListener('mouseover', () => {
        Option1yellow.style.cursor = 'pointer';
      });

      Option1yellow.addEventListener('mouseout', () => {
        Option1yellow.style.cursor = 'default';
      });
      answerOption1yellow.addEventListener('mouseover',()=>{
        answerOption1yellow.style.cursor = 'pointer';
      })

      Option2yellow.addEventListener('mouseover', () => {
        Option2yellow.style.cursor = 'pointer';
      });

      Option2yellow.addEventListener('mouseout', () => {
        Option2yellow.style.cursor = 'default';
      });
      answerOption2yellow.addEventListener('mouseover',()=>{
        answerOption2yellow.style.cursor = 'pointer';
      })
      Option3yellow.addEventListener('mouseover', () => {
        Option3yellow.style.cursor = 'pointer';
      });

      Option3yellow.addEventListener('mouseout', () => {
        Option3yellow.style.cursor = 'default';
      });
      answerOption3yellow.addEventListener('mouseover',()=>{
        answerOption3yellow.style.cursor = 'pointer';
      })

      answerOption1yellow.textContent = options[0].text;
      answerOption2yellow.textContent = options[1].text;
      answerOption3yellow.textContent = options[2].text;

      options.forEach((option, index) => {
        document.getElementById(`Option${index + 1}-yellow`).onclick = () => handleOptionClick(option.isCorrect);
        document.getElementById(`answer-Option${index + 1}-yellow`).onclick = () => handleOptionClick(option.isCorrect);
      });
    }
   
  }, [currentQuestionIndex, questionText, options, picture, navigate, showFeedback]);

  return (
    <div className="App-header-yellow">
      {showFeedback ? (
        <YellowPageAfter feedback={feedback} onNext={handleNext} isTrue={answerCorrect}/>
      ) : (
        <>
          <YellowPageSVG />
          <div className="question-text-div-yellow">
            <p className="question-text-yellow">{questionText}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default YellowPage;