import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GreenPageEndSVG } from '../../assets/SVG/GreenPageEnd.svg';
import { green5 } from '../../assets';
import '../../styles/Layout.css';

const GreenPageEnd = () => {
  const navigate = useNavigate();
  const feedback = "Congratulations, the quiz is complete. Happy learning!"; 
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxGreen = document.getElementById('correct-box-green');
      const picturePuzzle = document.getElementById('picture_puzzle-green');
      const correctBoxGreentext = document.getElementById('correct-box-green-text');
      if (homeWiseathlete && correctBoxGreen  && picturePuzzle)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxGreen.addEventListener('mouseover', () => {
            correctBoxGreen.style.cursor = 'pointer';
        });
  
        correctBoxGreen.addEventListener('mouseout', () => {
            correctBoxGreen.style.cursor = 'default';
        });
        correctBoxGreen.addEventListener('click', () => {
            navigate('/');
        });

        picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', green5);


      }
     
    },[]);
  return (
    <div className="App-header-green">
      <GreenPageEndSVG/>
      <div className="question-text-div-green">
          <p className="question-text-green">{feedback}</p>
      </div>
    </div>
  );
}

export default GreenPageEnd;