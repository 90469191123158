import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as YellowPageEndSVG } from '../../assets/SVG/YellowPageEnd.svg';
import { yellow4 } from '../../assets';
import '../../styles/Layout.css';

const YellowPageEnd = () => {
  const navigate = useNavigate();
  const feedback = "Congratulations, the quiz is complete. Happy learning!"; 
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxYellow = document.getElementById('correct-box-yellow');
      const picturePuzzle = document.getElementById('picture_puzzle-yellow');
      const correctBoxYellowtext = document.getElementById('correct-box-yellow-text');
      if (homeWiseathlete && correctBoxYellow  && picturePuzzle)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxYellow.addEventListener('mouseover', () => {
            correctBoxYellow.style.cursor = 'pointer';
        });
  
        correctBoxYellow.addEventListener('mouseout', () => {
            correctBoxYellow.style.cursor = 'default';
        });
        correctBoxYellow.addEventListener('click', () => {
            navigate('/');
        });

        picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', yellow4);


      }
     
    },[]);
  return (
    <div className="App-header-yellow">
      <YellowPageEndSVG/>
      <div className="question-text-div-yellow">
          <p className="question-text-yellow">{feedback}</p>
      </div>
    </div>
  );
}

export default YellowPageEnd;