import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BluePageEndSVG } from '../../assets/SVG/BluePageEnd.svg';
import { blue6 } from '../../assets';
import '../../styles/Layout.css';

const BluePageEnd = () => {
  const navigate = useNavigate();
  const feedback = "Congratulations, the quiz is complete. Happy learning!"; 
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxBlue = document.getElementById('correct-box-blue');
      const picturePuzzle = document.getElementById('picture_puzzle-blue');
      const correctBoxBluetext = document.getElementById('correct-box-blue-text');
      if (homeWiseathlete && correctBoxBlue  && picturePuzzle)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxBlue.addEventListener('mouseover', () => {
            correctBoxBlue.style.cursor = 'pointer';
        });
  
        correctBoxBlue.addEventListener('mouseout', () => {
            correctBoxBlue.style.cursor = 'default';
        });
        correctBoxBlue.addEventListener('click', () => {
            navigate('/');
        });

        picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', blue6);


      }
     
    },[]);
  return (
    <div className="App-header-blue">
      <BluePageEndSVG/>
      <div className="question-text-div-blue">
          <p className="question-text-blue">{feedback}</p>
      </div>
    </div>
  );
}

export default BluePageEnd;