import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RedPageEndSVG } from '../../assets/SVG/RedPageEnd.svg';
import { red4 } from '../../assets';
import '../../styles/Layout.css';

const RedPageEnd = () => {
  const navigate = useNavigate();
  const feedback = "Congratulations, the quiz is complete. Happy learning!"; 
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxRed = document.getElementById('correct-box-red');
      const picturePuzzle = document.getElementById('picture_puzzle-red');
      const correctBoxRedtext = document.getElementById('correct-box-red-text');
      if (homeWiseathlete && correctBoxRed  && picturePuzzle)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxRed.addEventListener('mouseover', () => {
            correctBoxRed.style.cursor = 'pointer';
        });
  
        correctBoxRed.addEventListener('mouseout', () => {
            correctBoxRed.style.cursor = 'default';
        });
        correctBoxRed.addEventListener('click', () => {
            navigate('/');
        });

        picturePuzzle.setAttributeNS('http://www.w3.org/1999/xlink', 'href', red4);


      }
     
    },[]);
  return (
    <div className="App-header-red">
      <RedPageEndSVG/>
      <div className="question-text-div-red">
          <p className="question-text-red">{feedback}</p>
      </div>
    </div>
  );
}

export default RedPageEnd;